import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTheme } from 'src/hooks';
import imgFallback from 'src/utils/image';

function Slide({ isActive, project, isMobile }) {
  const {
    navigation,
    global: { readMore },
  } = useTheme();

  return (
    <Container fluid className={['primary-carousel-item px-0', isActive ? 'active' : null].join(' ').trim()} id={project.id}>
      <Row noGutters>
        <Col xs={12} lg={5} xl={6} className="py-3">
          <img
            src={project.image?.image?.asset?.gatsbyImageData?.images?.fallback?.src ?? imgFallback}
            alt={project.image?.alt ?? ''}
            style={{ maxWidth: '100%' }}
          />
        </Col>
        <Col xs={12} lg={7} xl={{ span: 5, offset: 1 }} className="px-lg-3 py-lg-3 d-flex justify-content-start align-items-center px-2">
          <div className="fade-in">
            <div className="text-size-md font-weight-semi-bold text-uppercase letter-spacing-xl text-white-75 mb-1">
              {project.sectionTitle}
            </div>
            <h3 className="mb-3 text-white">{project.title}</h3>
            <div className="font-weight-bold mb-lg-5 text-white-75 mb-3">{project.services.join(', ')}</div>
            {/* eslint-disable-next-line react/no-danger */}
            {!isMobile && (
              <div
                dangerouslySetInnerHTML={{ __html: project.description }}
                className="mb-6 text-white"
                style={{ color: 'rgba(0, 0, 0, 0.56)' }}
              />
            )}
            <Link to={`/${navigation.projects.path}/${project.link}`} className="btn-link-secondary" tabIndex={isActive ? 0 : -1}>
              {readMore}
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function SliderDot({ label, isActive, onClick }) {
  return (
    <button
      type="button"
      tabIndex="0"
      onClick={onClick}
      onFocus={onClick}
      onMouseOver={onClick}
      className={['primary-carousel-indicator', isActive ? 'active' : null].join(' ').trim()}
      aria-label={`Tab 0${label}`}>
      {`0${label}`}
    </button>
  );
}

function BlockContentProjectsCarousel() {
  const data = useStaticQuery(graphql`
    {
      allSanityProject(sort: { order: DESC, fields: _createdAt }) {
        nodes {
          ...getFeaturedProject
        }
      }
    }
  `);

  const {
    section: { featuredProjects: featuredProjectsThemeStrings },
  } = useTheme();

  const projects = data.allSanityProject.nodes;

  const featuredProjects = projects.filter((project) => project.isFeatured === true);

  const [active, setActive] = useState(0);
  const handleClick = (e) => setActive(e);

  return (
    <Container fluid className="bg-gradient-dark-reverse position-relative px-0 py-6">
      <Row noGutters className="d-none d-lg-flex primary-carouse-desktop-only">
        <Col xs={12} md={10} xl={10}>
          {featuredProjects.map((project, i) => (
            <Slide
              key={project.id}
              isActive={i === active}
              project={{
                sectionTitle: featuredProjectsThemeStrings.title,
                id: project.id,
                link: project.slug.current,
                image: project.previewImageDesktop,
                title: project.title,
                description: project.description,
                services: project.services,
              }}
            />
          ))}
        </Col>
        <Col xs={12} md={2} xl={2} className="py-xl-3 d-flex justify-content-end justify-content-xl-center align-items-center">
          <Row noGutters className="primary-carousel-indicators flex-column justify-content-center">
            {featuredProjects.map((project, i) => (
              <Col xs={12} key={project.id}>
                <SliderDot id={project.id} label={i + 1} onClick={() => handleClick(i)} isActive={i === active} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      {/* eslint-disable-next-line max-len */}
      <Row className="scroll-snap-type-x-mandatory scrollbar-primary pb-lg-0 d-lg-none primary-carouse-mobile-only mx-0 flex-nowrap overflow-auto pb-6">
        {featuredProjects.map((project) => (
          <Col key={project.id} xs={10} sm={8} className="scroll-snap-align-start">
            <Slide
              isActive
              isMobile
              project={{
                sectionTitle: featuredProjectsThemeStrings.title,
                id: project.id,
                link: project.slug.current,
                image: project.previewImageMobile,
                title: project.title,
                description: project.description,
                services: project.services,
              }}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default BlockContentProjectsCarousel;
